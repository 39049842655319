// src/components/SupplierList.js

import React, { useState, useEffect } from 'react';
import { fetchSuppliers } from '../api';
import { Spinner } from 'react-bootstrap';

const SupplierList = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true); // Laadstatus

  useEffect(() => {
    const getSuppliers = async () => {
      try {
        const response = await fetchSuppliers();
        setSuppliers(response.data);
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      } finally {
        setLoading(false); // Zet de laadstatus naar false
      }
    };

    getSuppliers();
  }, []);

  return (
    <div className="section">
      <h1>Suppliers</h1>
      {loading ? ( // Als loading true is, toon de laadindicator
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : ( // Anders toon de leveranciers
        <ul>
          {suppliers.map(supplier => (
            <li key={supplier.id}>
              <strong>{supplier.naam}</strong>: {supplier.contactgegevens}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SupplierList;