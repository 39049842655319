// src/App.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import ProductList from './components/ProductList';
import CategoryList from './components/CategoryList';
import SupplierList from './components/SupplierList';
import LocationList from './components/LocationList';
import Dashboard from './components/Dashboard';
import LocationDetails from './components/LocationDetails';

function App() {
  return (
    <Router>
      <div className="App">
        <Sidebar />
        <div className="content">
          <main className="container mt-4">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/products" element={<ProductList />} />
              <Route path="/categories" element={<CategoryList />} />
              <Route path="/suppliers" element={<SupplierList />} />
              <Route path="/locations" element={<LocationList />} />
              <Route path="/location/:locationId" element={<LocationDetails />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;