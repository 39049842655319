// src/components/Sidebar.js

import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../Sidebar.css';
import { FaHome, FaBox, FaTags, FaTruck, FaMapMarkedAlt, FaQrcode } from 'react-icons/fa';  // Import FaQrcode icon

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h3>Van Lierde P. & L.</h3>
      </div>
      <Nav className="flex-column">
        <Nav.Item>
          <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
            <FaHome className="nav-icon" /> Dashboard
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/products" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
            <FaBox className="nav-icon" /> Producten en voorraad
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/categories" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
            <FaTags className="nav-icon" /> Productcategorieën
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/suppliers" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
            <FaTruck className="nav-icon" /> Leveranciers
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/locations" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
            <FaMapMarkedAlt className="nav-icon" /> Locaties
          </NavLink>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default Sidebar;