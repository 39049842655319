// src/components/CategoryList.js

import React, { useState, useEffect } from 'react';
import { fetchCategories } from '../api';
import { Spinner } from 'react-bootstrap';

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loading, setLoading] = useState(true); // Laadstatus

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetchCategories();
        setCategories(response.data);
        setFilteredCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false); // Zet de laadstatus naar false
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    const results = categories.filter(category =>
      category.naam.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(results);
  }, [searchTerm, categories]);

  return (
    <div className="section">
      <h1>Categories</h1>
      <input
        type="text"
        placeholder="Search categories..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className="form-control mb-3"
      />
      {loading ? ( // Als loading true is, toon de laadindicator
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : ( // Anders toon de categorieën
        <ul>
          {filteredCategories.map(category => (
            <li key={category.id}>
              <strong>{category.naam}</strong>: {category.beschrijving}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CategoryList;