// src/components/LocationDetails.js

import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import '../LocationDetails.css';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const LocationDetails = () => {
  const { locationId } = useParams();
  const [location, setLocation] = useState(null);
  const [products, setProducts] = useState([]);
  const [newStock, setNewStock] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://inventory.van-lierde.be/api/location/${locationId}/`)
      .then(response => response.json())
      .then(data => {
        setLocation(data.location);
        setProducts(data.products);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching location details:', error);
        setLoading(false);
      });
  }, [locationId]);

  const handleStockChange = (productId, stock) => {
    setNewStock({ ...newStock, [productId]: stock });
  };

  const updateStock = (productId, stock) => {
    fetch('https://inventory.van-lierde.be/api/update_stock/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ product_id: productId, new_stock: stock }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          setProducts(products.map(product => product.id === productId ? { ...product, voorraad: stock } : product));
        } else {
          alert('Error updating stock');
        }
      })
      .catch(error => console.error('Error updating stock:', error));
  };

  const debouncedUpdateStock = useCallback(debounce(updateStock, 500), [products]);

  const increaseStock = (productId, currentStock) => {
    const newStockValue = parseInt(currentStock) + 1;
    if (!isNaN(newStockValue)) {
      handleStockChange(productId, newStockValue);
      debouncedUpdateStock(productId, newStockValue);
    }
  };

  const decreaseStock = (productId, currentStock) => {
    const newStockValue = parseInt(currentStock) - 1;
    if (!isNaN(newStockValue) && newStockValue >= 0) {
      handleStockChange(productId, newStockValue);
      debouncedUpdateStock(productId, newStockValue);
    }
  };

  return (
    <div className="location-details">
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {location && (
            <>
              <h1>{location.beschrijving}</h1>
              <div className="qr-code-container">
                <img src={`https://inventory.van-lierde.be${location.qr_code}`} alt="QR Code" />
              </div>
            </>
          )}
          <h2>Products</h2>
          <div className="products-container">
            {products.length > 0 ? (
              products.map(product => (
                <div key={product.id} className="product-card">
                  <h3>{product.naam}</h3>
                  <p>Current Stock: {product.voorraad}</p>
                  <div className="stock-control">
                    <button onClick={() => decreaseStock(product.id, newStock[product.id] !== undefined ? newStock[product.id] : product.voorraad)}>-</button>
                    <input
                      type="number"
                      value={newStock[product.id] !== undefined ? newStock[product.id] : product.voorraad}
                      onChange={(e) => handleStockChange(product.id, e.target.value)}
                      onBlur={() => debouncedUpdateStock(product.id, newStock[product.id] !== undefined ? newStock[product.id] : product.voorraad)}
                    />
                    <button onClick={() => increaseStock(product.id, newStock[product.id] !== undefined ? newStock[product.id] : product.voorraad)}>+</button>
                  </div>
                </div>
              ))
            ) : (
              <p>No products found.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LocationDetails;