// src/components/AddProductModal.js

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { addProduct, fetchCategories, fetchSuppliers, fetchLocations } from '../api';

const AddProductModal = ({ show, handleClose, handleAddProduct }) => {
  const [naam, setNaam] = useState('');
  const [beschrijving, setBeschrijving] = useState('');
  const [voorraad, setVoorraad] = useState('');
  const [minimaleVoorraad, setMinimaleVoorraad] = useState('');
  const [categorie, setCategorie] = useState('');
  const [leverancier, setLeverancier] = useState('');
  const [locatie, setLocatie] = useState('');
  const [loading, setLoading] = useState(true);
  const [addingProduct, setAddingProduct] = useState(false);

  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [categoriesResponse, suppliersResponse, locationsResponse] = await Promise.all([
          fetchCategories(),
          fetchSuppliers(),
          fetchLocations()
        ]);
        setCategories(categoriesResponse.data);
        setSuppliers(suppliersResponse.data);
        setLocations(locationsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newProduct = {
      naam,
      beschrijving,
      voorraad: parseInt(voorraad),
      minimale_voorraad: parseInt(minimaleVoorraad),
      categorie: parseInt(categorie),
      leverancier: parseInt(leverancier),
      locatie: parseInt(locatie)
    };
    console.log('Submitting new product:', newProduct);
    try {
      setAddingProduct(true);
      const response = await addProduct(newProduct);
      console.log('Product added successfully:', response.data);
      handleAddProduct(response.data);
      handleClose();
    } catch (error) {
      console.error("Error adding product:", error);
      console.log('Response data:', error.response.data);
    } finally {
      setAddingProduct(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="productNaam">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={naam}
                onChange={(e) => setNaam(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="productBeschrijving">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={beschrijving}
                onChange={(e) => setBeschrijving(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="productVoorraad">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                type="number"
                value={voorraad}
                onChange={(e) => setVoorraad(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="productMinimaleVoorraad">
              <Form.Label>Minimum Stock</Form.Label>
              <Form.Control
                type="number"
                value={minimaleVoorraad}
                onChange={(e) => setMinimaleVoorraad(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="productCategorie">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={categorie}
                onChange={(e) => setCategorie(e.target.value)}
                required
              >
                <option value="">Select a category</option>
                {categories.map(cat => (
                  <option key={cat.id} value={cat.id}>{cat.naam}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="productLeverancier">
              <Form.Label>Supplier</Form.Label>
              <Form.Control
                as="select"
                value={leverancier}
                onChange={(e) => setLeverancier(e.target.value)}
                required
              >
                <option value="">Select a supplier</option>
                {suppliers.map(supplier => (
                  <option key={supplier.id} value={supplier.id}>{supplier.naam}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="productLocatie">
              <Form.Label>Location</Form.Label>
              <Form.Control
                as="select"
                value={locatie}
                onChange={(e) => setLocatie(e.target.value)}
                required
              >
                <option value="">Select a location</option>
                {locations.map(loc => (
                  <option key={loc.id} value={loc.id}>{loc.beschrijving}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={addingProduct}>
              {addingProduct ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  Adding...
                </>
              ) : (
                'Add Product'
              )}
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddProductModal;