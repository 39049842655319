import axios from 'axios';

const API_URL = 'https://inventory.van-lierde.be/api';

export const fetchProducts = () => {
  return axios.get(`${API_URL}/producten/`);
};

export const fetchCategories = () => {
  return axios.get(`${API_URL}/categories/`);
};

export const fetchSuppliers = () => {
  return axios.get(`${API_URL}/leveranciers/`);
};

export const fetchLocations = () => {
  return axios.get(`${API_URL}/magazijnlocaties/`);
};

export const addProduct = (product) => {
  return axios.post(`${API_URL}/producten/`, product);
};