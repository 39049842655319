import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const Dashboard = () => {
  const [lowStockProducts, setLowStockProducts] = useState([]);
  const [reportType, setReportType] = useState('product_usage');
  const [reportData, setReportData] = useState([]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [generatedDateFrom, setGeneratedDateFrom] = useState('');
  const [generatedDateTo, setGeneratedDateTo] = useState('');
  const [loadingLowStock, setLoadingLowStock] = useState(true);
  const [loadingReport, setLoadingReport] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false); // Laadstatus voor PDF-generatie
  const [searchTerm, setSearchTerm] = useState(''); // Zoekterm status
  const [searchResults, setSearchResults] = useState([]); // Zoekresultaten status
  const [loadingSearch, setLoadingSearch] = useState(false); // Laadstatus voor zoeken

  useEffect(() => {
    fetchLowStockProducts();
    setDefaultDates();
  }, []);

  const fetchLowStockProducts = async () => {
    try {
      const response = await axios.get('https://inventory.van-lierde.be/api/producten/low_stock/');
      setLowStockProducts(response.data);
    } catch (error) {
      console.error('Error fetching low stock products:', error);
    } finally {
      setLoadingLowStock(false);
    }
  };

  const setDefaultDates = () => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const formattedToday = today.toISOString().split('T')[0];
    const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

    setDateTo(formattedToday);
    setDateFrom(formattedOneMonthAgo);
  };

  const fetchReportData = async (e) => {
    e.preventDefault();
    const { from, to } = getDateRange();
    setLoadingReport(true);

    try {
      const response = await axios.get(`https://inventory.van-lierde.be/api/producten/${reportType}/`, {
        params: { since: from, until: to },
      });
      setReportData(response.data);
      setGeneratedDateFrom(from);
      setGeneratedDateTo(to);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setLoadingReport(false);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getDateRange = () => {
    const currentDate = getCurrentDate();
    const from = dateFrom || `${currentDate.slice(0, 4)}-01-01`;
    const to = dateTo || currentDate;
    return { from, to };
  };

  const downloadReport = async () => {
    const { from, to } = getDateRange();
    setGeneratingPDF(true); // Zet de laadstatus naar true

    try {
      const params = { since: from, until: to, report_type: reportType };
      const response = await axios.get('https://inventory.van-lierde.be/api/producten/download_report/', {
        params,
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));

      let reportName = `report_${new Date().toISOString().slice(0, 10)}.pdf`;

      if (reportType === 'product_changes') {
        reportName = `product_changes_${from}_${to}.pdf`;
      } else if (reportType === 'total_inventory') {
        reportName = `total_inventory_${to}.pdf`;
      } else if (reportType === 'product_usage') {
        reportName = `product_usage_${from}_${to}.pdf`;
      }

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', reportName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading report:', error);
    } finally {
      setGeneratingPDF(false); // Zet de laadstatus naar false
    }
  };

  const handleReportTypeChange = (e) => {
    setReportType(e.target.value);
    setReportData([]);
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === '') {
      return;
    }

    setLoadingSearch(true);
    try {
      const response = await axios.get('https://inventory.van-lierde.be/api/producten/', {
        params: { search: searchTerm.trim() },
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching products:', error);
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <div className="section">
            <h2>Rapportages</h2>
            <form onSubmit={fetchReportData}>
              <div className="form-group">
                <label htmlFor="reportType">Soort</label>
                <select id="reportType" className="form-control" value={reportType} onChange={handleReportTypeChange}>
                  <option value="product_usage">Productgebruik</option>
                  <option value="total_inventory">Totale voorraad</option>
                  <option value="product_changes">Productwijzigingen</option>
                </select>
              </div>
              {reportType !== 'total_inventory' && (
                <>
                  <div className="form-group">
                    <label htmlFor="dateFrom">Datum van</label>
                    <input type="date" id="dateFrom" className="form-control" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dateTo">Datum t/m</label>
                    <input type="date" id="dateTo" className="form-control" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
                  </div>
                </>
              )}
              <button type="submit" className="btn btn-primary">Genereer</button>
              <button type="button" className="btn btn-secondary ml-2" onClick={downloadReport} disabled={generatingPDF}>
                {generatingPDF ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  'Download PDF'
                )}
              </button>
            </form>
            {loadingReport ? (
              <div className="d-flex justify-content-center mt-3">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              reportData.length > 0 && (
                <div className="report-results">
                  <h3>Rapportresultaten</h3>
                  {reportType === 'product_changes' && (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Productnaam</th>
                            <th>Nieuwe voorraad</th>
                            <th>Oude voorraad</th>
                            <th>Datum wijziging</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.product_naam}</td>
                              <td>{item.new_stock}</td>
                              <td>{item.old_stock}</td>
                              <td>{new Date(item.date_changed).toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {reportType === 'total_inventory' && (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Productnaam</th>
                            <th>Voorraad</th>
                            <th>Locatie</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.naam}</td>
                              <td>{item.voorraad}</td>
                              <td>{item.locatie__beschrijving}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {reportType === 'product_usage' && (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Productnaam</th>
                            <th>Gebruikt</th>
                            <th>Bijgekocht</th>
                            <th>{`Voorraad op ${generatedDateFrom}`}</th>
                            <th>{`Voorraad op ${generatedDateTo}`}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.product__naam}</td>
                              <td style={{ color: item.used > 0 ? 'red' : 'black' }}>{item.used}</td>
                              <td style={{ color: item.bought > 0 ? 'green' : 'black' }}>{item.bought}</td>
                              <td>{item.beginvoorraad}</td>
                              <td>
                                {item.eindvoorraad}
                                {item.eindvoorraad - item.beginvoorraad !== 0 && (
                                  <span style={{ color: item.eindvoorraad - item.beginvoorraad > 0 ? 'green' : 'red' }}>
                                    {` (${item.eindvoorraad - item.beginvoorraad > 0 ? '+' : ''}${item.eindvoorraad - item.beginvoorraad})`}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="section">
            <h2>Te bestellen producten</h2>
            {loadingLowStock ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <ul>
                {lowStockProducts.map((product) => (
                  <li key={product.id}>{product.naam} - Huidige voorraad: {product.voorraad} - Minimale voorraad: {product.minimale_voorraad}</li>
                ))}
              </ul>
            )}
          </div>
          <div className="section">
            <h2>Snel zoeken</h2>
            <input
              type="text"
              className="form-control"
              placeholder="Uw zoekopdracht"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleSearchKeyPress}
            />
            <button className="btn btn-primary mt-3" onClick={handleSearch}>Zoeken</button>
            {loadingSearch ? (
              <div className="d-flex justify-content-center mt-3">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              searchResults.length > 0 && (
                <ul className="mt-3">
                  {searchResults.map(product => (
                    <li key={product.id}>
                      <strong>{product.naam}</strong>: {product.beschrijving} - Locatie: {product.locatie.beschrijving} - Voorraad: {product.voorraad}
                    </li>
                  ))}
                </ul>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;