// src/components/LocationList.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { fetchLocations } from '../api';
import '../LocationList.css';

const LocationList = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedQR, setSelectedQR] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getLocations = async () => {
      try {
        const response = await fetchLocations();
        setLocations(response.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
      } finally {
        setLoading(false);
      }
    };

    getLocations();
  }, []);

  const handleLocationClick = (locationId) => {
    navigate(`/location/${locationId}`);
  };

  const handleQRClick = (qrCodeUrl) => {
    setSelectedQR(qrCodeUrl);
  };

  const handleCloseQR = () => {
    setSelectedQR(null);
  };

  return (
    <div className="section">
      <h1>Locations</h1>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <ul className="location-list">
          {locations.map(location => (
            <li key={location.id} className="location-item">
              <span
                className="location-description"
                onClick={() => handleLocationClick(location.id)}
              >
                <strong>{location.beschrijving}</strong>
              </span>
              <img
                src={`https://inventory.van-lierde.be/media/qr_codes/qr_code_${location.id}.png`}
                alt="QR Code"
                className="qr-code-small"
                onClick={() => handleQRClick(`https://inventory.van-lierde.be/media/qr_codes/qr_code_${location.id}.png`)}
              />
            </li>
          ))}
        </ul>
      )}
      {selectedQR && (
        <div className="qr-modal" onClick={handleCloseQR}>
          <div className="qr-modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={handleCloseQR}>&times;</span>
            <img src={selectedQR} alt="QR Code Large" className="qr-code-large" />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationList;