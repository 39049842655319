// src/components/ProductList.js

import React, { useState, useEffect } from 'react';
import { fetchProducts } from '../api';
import AddProductModal from './AddProductModal';
import { Button, Spinner } from 'react-bootstrap';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true); // Laadstatus

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await fetchProducts();
        setProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false); // Zet de laadstatus naar false
      }
    };

    getProducts();
  }, []);

  useEffect(() => {
    const results = products.filter(product =>
      product.naam.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(results);
  }, [searchTerm, products]);

  const handleAddProduct = (newProduct) => {
    setProducts([...products, newProduct]);
  };

  return (
    <div className="section">
      <h1 className="mb-4">Products</h1>
      <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
        Add Product
      </Button>
      <input
        type="text"
        placeholder="Search products..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className="form-control mb-3"
      />
      {loading ? ( // Als loading true is, toon de laadindicator
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : ( // Anders toon de producten
        <ul className="list-group">
          {filteredProducts.map(product => (
            <li key={product.id} className="list-group-item">
              <strong>{product.naam}</strong>: {product.beschrijving} - Locatie: {product.locatie.beschrijving} - Voorraad: {product.voorraad}
            </li>
          ))}
        </ul>
      )}
      <AddProductModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleAddProduct={handleAddProduct}
      />
    </div>
  );
};

export default ProductList;